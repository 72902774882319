import React from "react";
import _ from "lodash";
import moment from "moment-strftime";
import { graphql } from "gatsby";

import { Layout } from "../components/index";
import Header from "../components/Header";
import { Link, withPrefix } from "../utils";
import Footer from "../components/Footer";

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query ($url: String) {
    sitePage(path: { eq: $url }) {
      id
    }
  }
`;

export default class Home extends React.Component {
  render() {
    let pages = _.filter(
      this.props.pageContext.pages,
      (p) =>
        p.frontmatter &&
        p.frontmatter.template === "page" &&
        p.frontmatter.indexOrder
    );
    pages = _.orderBy(pages, "frontmatter.indexOrder", "asc");

    let technicalRef = _.filter(
      this.props.pageContext.pages,
      (p) =>
        p.frontmatter &&
        p.frontmatter.template === "post" &&
        p.frontmatter.category === "technicalRef"
    );
    technicalRef = _.orderBy(technicalRef, "frontmatter.title", "desc");

    let theRest = _.filter(
      this.props.pageContext.pages,
      (p) =>
        p.frontmatter &&
        p.frontmatter.template === "post" &&
        p.frontmatter.category !== "technicalRef"
    );
    theRest = _.orderBy(theRest, "frontmatter.date", "desc");
    return (
      <Layout {...this.props}>
        <Header
          {...this.props}
          site={this.props.pageContext.site}
          page={this.props.pageContext}
          image={_.get(
            this.props,
            "pageContext.site.siteMetadata.header.background_img",
            null
          )}
        />
        <div id="content" className="site-content">
          <main id="main" className="site-main inner">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title", null)}
                </h1>
              </header>

              <p>Welcome to my small corner on the internet!</p>

              <h2>Pages</h2>

              <ul>
                {_.map(pages, (post, post_idx) => (
                  <li>
                    <Link
                      to={withPrefix(_.get(post, "url", null))}
                      rel="bookmark"
                    >
                      {_.get(post, "frontmatter.title", null)}
                    </Link>
                  </li>
                ))}
              </ul>

              <h2>Technical Expositions</h2>

              <p>
                These are notes I write when I hit particularly interesting or
                difficult problems. They are small and act as a reference
                implementations I use when I need to do something similar.
              </p>

              <ul>
                {_.map(technicalRef, (post, post_idx) => (
                  <li>
                    <Link
                      to={withPrefix(_.get(post, "url", null))}
                      rel="bookmark"
                    >
                      {_.get(post, "frontmatter.title", null)}
                    </Link>
                  </li>
                ))}
              </ul>

              <h2>Blog Posts</h2>

              <p>The rest of stuff I have written here.</p>

              <ul>
                {_.map(theRest, (post, post_idx) => (
                  <li>
                    <Link
                      to={withPrefix(_.get(post, "url", null))}
                      rel="bookmark"
                    >
                      <time
                        className="published"
                        dateTime={moment(
                          _.get(post, "frontmatter.date", null)
                        ).strftime("%Y-%m-%d %H:%M")}
                      >
                        {moment(_.get(post, "frontmatter.date", null)).strftime(
                          "%B %d, %Y"
                        )}
                      </time>
                      {" – "}
                      {_.get(post, "frontmatter.title", null)}
                    </Link>
                  </li>
                ))}
              </ul>
            </article>
          </main>
          <Footer
            {...this.props}
            site={this.props.pageContext.site}
            page={this.props.pageContext}
            image={_.get(
              this.props,
              "pageContext.site.siteMetadata.header.background_img",
              null
            )}
          />
        </div>
      </Layout>
    );
  }
}
